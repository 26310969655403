import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, Button, Table, Form, Alert, Spinner } from "react-bootstrap"; // Add Spinner here
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faExclamationCircle } from "@fortawesome/free-solid-svg-icons"; // Add faExclamationCircle here
import { Modal } from "react-bootstrap"; // Add Modal import


import HeadingBanner from "../components/HeadingBanner";
import LeftPanel from "../components/LeftPanel";
import SchoolRollNumberInput from "../components/SchoolRollNumberInput";
import OrientationPrompt from "../components/OrientationPrompt";
import * as XLSX from "xlsx";
import JSZip from "jszip";
import { saveAs } from "file-saver";

import {
  whoami,
  getClasses,
  getTeachersBySchoolId,
  getSchools,
  getClassesByAssessmentId,
  getClassesAssessmentResults,
  postAladdinExportAuditLog,
  sendAladdinResultsEmail
} from "../services/APIClient";

import {
  groupClassResultsforExport
} from "../services/GroupAndAverageTasks";

import "../components/AladdinExport.css";

function AladdinExport() {
  const [schoolid, setSchoolid] = useState(null);
  const [schoolName, setSchoolName] = useState(null);
  const [teacherid, setTeacherid] = useState(null);
  const [teachers, setTeachers] = useState(null);
  const [rollNumber, setRollNumber] = useState(null);
  const [teacherEmail, setTeacherEmail] = useState(null);
  const [teacherName, setTeacherName] = useState(null);
  const [classes, setClasses] = useState([]);
  const [screeners, setScreeners] = useState([]);
  const [selectedScreener, setSelectedScreener] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedClasses, setSelectedClasses] = useState([]); // Store selected class IDs
  const [selectAll, setSelectAll] = useState(false);
  const [assessmentResults, setAssessmentResults] = useState(null); // Store fetched results
  const [exportLoading, setExportLoading] = useState(false);
  const [selectedYear, setSelectedYear] = useState(null);
  const [emailLoading, setEmailLoading] = useState(false);
  const [showModal, setShowModal] = useState(false); // State for modal visibility



  const YEAR_RANGES = {
    "24/25": { start: "2024-08-15", end: "2025-08-15" },
    "23/24": { start: "2023-08-15", end: "2024-08-15" },
    "22/23": { start: "2022-08-15", end: "2023-08-15" }
  };

  useEffect(() => {
    whoami()
      .then((response) => {
        setTeacherid(response.data.stakeholder.id);
        setTeacherEmail(response.data.email);
        setTeacherName(response.data.name);
      })
      .catch((error) => console.error("Error fetching user info:", error));
  }, []);

  useEffect(() => {
    async function fetchSchoolData() {
      if (!schoolid) return;

      try {
        setLoading(true);

        const teachersResponse = await getTeachersBySchoolId(schoolid);
        setTeachers(teachersResponse);

        const classesResponse = await getClasses();
        classesResponse.sort((a, b) => new Date(b.date_created) - new Date(a.date_created));
        setClasses(classesResponse);

        const schoolsResponse = await getSchools();
        const matchingSchool = schoolsResponse.data.find((school) => school.id === schoolid);

        if (matchingSchool) {
          setRollNumber(matchingSchool.rollnumber);
          setSchoolName(matchingSchool.name);

          const screenersAvailable = await fetchScreenersForSchool(schoolid);
          setScreeners(screenersAvailable);
        }
      } catch (error) {
        console.error("Error fetching school data:", error);
      } finally {
        setLoading(false);
      }
    }

    fetchSchoolData();
  }, [schoolid]);

  useEffect(() => {
    async function fetchClassesForScreener() {
      if (!selectedScreener || !schoolid || !selectedYear) return;

      try {
        setLoading(true);
        const allClasses = await getClassesByAssessmentId(selectedScreener, schoolid);

        // Get the selected year's start & end dates
        const yearRange = YEAR_RANGES[selectedYear];
        if (!yearRange) {
          console.error("Invalid year selected:", selectedYear);
          return;
        }

        const startDate = new Date(yearRange.start);
        const endDate = new Date(yearRange.end);

        // Filter classes based on `date_updated`
        const filteredClasses = allClasses.filter(cls => {
          const updatedDate = new Date(cls.date_updated);
          return updatedDate >= startDate && updatedDate < endDate;
        });

        setClasses(filteredClasses);
        setSelectedClasses([]); // Reset selection when year changes
        setSelectAll(false);
      } catch (error) {
        console.error("Error fetching classes for selected screener:", error);
      } finally {
        setLoading(false);
      }
    }

    fetchClassesForScreener();
  }, [selectedScreener, schoolid, selectedYear]);


  const fetchScreenersForSchool = async (schoolId) => {
    return [
      { id: '68ce3a92-73e0-4158-9c08-31bff6246d23', name: "Jnr Baseline Assessment" },
      { id: '6a43ae18-2251-4876-8f15-23e2c897fdab', name: "Jnr Midpoint Assessment" },
      { id: 'd613e45e-b3b0-4d0f-bcdc-cc17f38bd285', name: "Jnr End of Year Assessment" },
      { id: '927b2379-998c-4424-af56-1f82e0144765', name: "Snr Baseline Screener" },
      { id: '9b962947-4f59-4e19-a725-40601b66e8ff', name: "Snr Midpoint Screener" },
      { id: '08e97976-f32f-4ad6-a2df-7a2be0789d70', name: "Snr End of Year Assessment" },
    ];
  };

  const getIconFileName = (assessmentName) => {
    let name = assessmentName.toLowerCase();
    if (name.includes("baseline") || name.includes("autumn")) return "autumn";
    if (name.includes("midpoint") || name.includes("spring")) return "spring";
    if (name.includes("end of year") || name.includes("summer")) return "summer";
    return "default";
  };

  const handleClassSelect = (classId) => {
    setSelectedClasses((prevSelected) =>
      prevSelected.includes(classId)
        ? prevSelected.filter((id) => id !== classId)
        : [...prevSelected, classId]
    );
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedClasses([]); // Unselect all
    } else {
      setSelectedClasses(classes.map((cls) => cls.classId)); // Select all
    }
    setSelectAll(!selectAll);
  };

  const generateZipFileName = () => {
    const schoolPart = schoolName ? schoolName.replace(/\s+/g, "_") : "School";
    const rollNumberPart = rollNumber ? rollNumber.replace(/\s+/g, "_") : "rollNumber";
    const assessmentPart = screeners.find((s) => s.id === selectedScreener)?.name || "Assessment";
    const formattedDate = formatDate(new Date().toISOString()); // Today's date in DD/MM/YY
    const yearPart = selectedYear ? `_${selectedYear}` : "";

    return `${rollNumberPart}_${schoolPart}_${assessmentPart}${yearPart}_${formattedDate}.zip`.replace(/\s+/g, "_");
  };



  // const exportResults = async () => {
  //   if (selectedClasses.length === 0) {
  //     alert("Please select at least one class to export results.");
  //     return;
  //   }

  //   setExportLoading(true);

  //   try {
  //     console.log(`Fetching assessment results for classes:`, selectedClasses);
  //     let response = await getClassesAssessmentResults(selectedClasses, selectedScreener);

  //     console.log("API Response:", response);

  //     if (!response || Object.keys(response).length === 0) {
  //       alert("No results found for the selected classes.");
  //       return;
  //     }

  //     // Group tasks properly before exporting
  //     response = groupClassResultsforExport(response);

  //     const zip = new JSZip();
  //     const classNames = [];

  //     selectedClasses.forEach((classId) => {
  //       const classData = response[classId];

  //       if (!classData || !classData.students) {
  //         console.warn(`No valid results for class ${classId}`);
  //         return;
  //       }

  //       const className = classData.name || `Class_${classId}`;
  //       classNames.push(className); // Store for audit log

  //       const assessmentName = screeners.find((s) => s.id === selectedScreener)?.name || "Assessment";
  //       const filename = `${className}_${assessmentName}.xlsx`.replace(/\s+/g, "_");

  //       let flattenedResults = [];
  //       let uniqueTasks = new Set();

  //       // Collect all unique task names for column headers
  //       Object.values(classData.students).forEach((student) => {
  //         student.results.forEach((assessment) => {
  //           assessment.groupedTasks.forEach((taskGroup) => {
  //             uniqueTasks.add(taskGroup.name);
  //           });
  //         });
  //       });

  //       const taskColumns = [...uniqueTasks];

  //       // Process student data
  //       Object.entries(classData.students).forEach(([studentId, student]) => {
  //         let studentRow = {
  //           "Student First and Last Name": student.name.trim(),
  //           "Test Date (D/M/Y)": formatDate(student.results[0]?.date), // Format the date
  //         };

  //         // Add task scores dynamically
  //         student.results.forEach((assessment) => {
  //           assessment.groupedTasks.forEach((taskGroup) => {
  //             const rawKey = `Raw Score (${taskGroup.name})`;
  //             const standardKey = `Standard Score (${taskGroup.name})`;

  //             studentRow[rawKey] = taskGroup.aggregated.rawScore ?? "N/A";
  //             studentRow[standardKey] = taskGroup.aggregated.standardScore ?? "N/A";
  //           });
  //         });

  //         flattenedResults.push(studentRow);
  //       });

  //       // Ensure column order is correct
  //       const headers = ["Student First and Last Name", "Test Date (D/M/Y)", ...taskColumns.flatMap(task => [`Raw Score (${task})`, `Standard Score (${task})`])];

  //       // Convert to Excel
  //       const worksheet = XLSX.utils.json_to_sheet(flattenedResults, { header: headers });
  //       const workbook = XLSX.utils.book_new();
  //       XLSX.utils.book_append_sheet(workbook, worksheet, "Results");

  //       const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
  //       zip.file(filename, excelBuffer);
  //     });

  //     if (Object.keys(zip.files).length === 0) {
  //       alert("No valid results to export.");
  //       return;
  //     }

  //     // Generate ZIP file and trigger download
  //     zip.generateAsync({ type: "blob" }).then((blob) => {
  //       saveAs(blob, generateZipFileName());
  //     });

  //     // ✅ **Audit Log API Call**
  //     const assessmentName = screeners.find((s) => s.id === selectedScreener)?.name || "Assessment";
  //     await postAladdinExportAuditLog(
  //       teacherid, // Current logged-in teacher ID
  //       schoolid,  // Selected school ID
  //       assessmentName,
  //       selectedScreener,
  //       selectedClasses, // List of class IDs
  //       classNames      // List of class names
  //     );

  //     alert("Export successful! Check your downloads.");
  //   } catch (error) {
  //     console.error("Error exporting assessment results:", error);
  //     alert("Failed to export results. Please try again.");
  //   } finally {
  //     setExportLoading(false);
  //   }
  // };
  const prepareResultsZip = async () => {
    if (selectedClasses.length === 0) {
      alert("Please select at least one class.");
      return null;
    }

    try {
      console.log(`Fetching results for classes:`, selectedClasses);
      let response = await getClassesAssessmentResults(selectedClasses, selectedScreener);

      console.log("API Response:", response);
      if (!response || Object.keys(response).length === 0) {
        alert("No results found.");
        return null;
      }

      response = groupClassResultsforExport(response);
      const zip = new JSZip();

      selectedClasses.forEach(classId => {
        const classData = response[classId];
        if (!classData || !classData.students) return;

        const className = classData.name || `Class_${classId}`;
        const assessmentName = screeners.find(s => s.id === selectedScreener)?.name || "Assessment";
        const filename = `${rollNumber}_${className}_${assessmentName}.xlsx`.replace(/\s+/g, "_");

        let flattenedResults = [];
        let uniqueTasks = new Set();

        Object.values(classData.students).forEach(student => {
          student.results.forEach(assessment => {
            assessment.groupedTasks.forEach(taskGroup => {
              uniqueTasks.add(taskGroup.name);
            });
          });
        });

        const taskColumns = [...uniqueTasks];

        Object.entries(classData.students).forEach(([studentId, student]) => {
          let rawDate = student.results[0]?.date;  // Extract raw date
          let formattedDate = rawDate ? formatDate(rawDate) : "";  // ✅ Format properly

          let totalScore = 0;
          let taskCount = 0;
  
          student.results.forEach(assessment => {
            assessment.groupedTasks.forEach(taskGroup => {
              if (taskGroup.aggregated.rawScore !== null) {
                totalScore += taskGroup.aggregated.rawScore;
                taskCount++;
              }
            });
          });
  
          let readingScore = taskCount > 0 ? Math.round(totalScore / taskCount) : ""; 
  
          let studentRow = {
            "Student First and Last Name": student.name.trim(),
            "Test Date (D/M/Y)": formattedDate,
            "Reading Score": readingScore, // ✅ Add Reading Score here
          };

          student.results.forEach(assessment => {
            assessment.groupedTasks.forEach(taskGroup => {
              studentRow[`Raw Score (${taskGroup.name})`] = taskGroup.aggregated.rawScore ?? "";
              studentRow[`Standard Score (${taskGroup.name})`] = taskGroup.aggregated.standardScore ?? "";
            });
          });

          flattenedResults.push(studentRow);
        });

        const headers = ["Student First and Last Name", "Test Date (D/M/Y)", "Reading Score", ...taskColumns.flatMap(task => [`Raw Score (${task})`, `Standard Score (${task})`])];
        const worksheet = XLSX.utils.json_to_sheet(flattenedResults, { header: headers });
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Results");

        const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
        zip.file(filename, excelBuffer);
      });

      if (Object.keys(zip.files).length === 0) {
        alert("No valid results.");
        return null;
      }

      return zip;
    } catch (error) {
      console.error("Error preparing ZIP:", error);
      alert("Error preparing ZIP file.");
      return null;
    }
  };

  const exportResults = async () => {
    if (selectedClasses.length === 0) {
      alert("Please select at least one class to export.");
      return;
    }

    setExportLoading(true);

    try {
      const zip = await prepareResultsZip();
      if (!zip) {
        setExportLoading(false);
        return;
      }

      // ✅ **Track the export via API call**
      const assessmentName = screeners.find((s) => s.id === selectedScreener)?.name || "Assessment";
      const classNames = selectedClasses.map(classId => classes.find(cls => cls.classId === classId)?.name || `Class_${classId}`);

      await postAladdinExportAuditLog(
        teacherid, // Current logged-in teacher ID
        schoolid,  // Selected school ID
        assessmentName,
        selectedScreener,
        selectedClasses, // List of class IDs
        classNames,      // List of class names
        "Export",
      );

      console.log("📝 Export tracked successfully in audit logs!");

      zip.generateAsync({ type: "blob" }).then(blob => {
        saveAs(blob, generateZipFileName());
        setExportLoading(false);
      });

    } catch (error) {
      console.error("❌ Error exporting results:", error);
      alert("Failed to export results.");
      setExportLoading(false);
    }
  };

  const emailResults = async () => {
    if (selectedClasses.length === 0) {
      alert("Please select at least one class to email results.");
      return;
    }

    setEmailLoading(true);

    try {
      console.log(`Fetching results for classes:`, selectedClasses);
      let response = await getClassesAssessmentResults(selectedClasses, selectedScreener);

      if (!response || Object.keys(response).length === 0) {
        alert("No results found.");
        setEmailLoading(false);
        return;
      }

      // ✅ Process data in the frontend
      response = groupClassResultsforExport(response);

      // ✅ Structure the payload
      const assessmentName = screeners.find((s) => s.id === selectedScreener)?.name || "Assessment";
      const classNames = selectedClasses.map(classId => response[classId]?.name || `Class_${classId}`);

      const emailPayload = {
        assessmentName,
        schoolName,
        rollNumber,
        selectedClasses: selectedClasses.map((classId) => ({
          classId,
          className: response[classId]?.name || `Class_${classId}`,
          students: response[classId]?.students || {},
        })),
      };

      console.log("📨 Sending email request with payload:", emailPayload);

      // ✅ **Track the export via API call**
      await postAladdinExportAuditLog(
        teacherid, // Current logged-in teacher ID
        schoolid,  // Selected school ID
        assessmentName,
        selectedScreener,
        selectedClasses, // List of class IDs
        classNames,     // List of class names
        "Email",
      );

      console.log("📝 Export tracked successfully in audit logs!");

      // ✅ Send structured data to backend
      await sendAladdinResultsEmail(teacherEmail, teacherName, emailPayload);

      alert("✅ Email sent successfully!");
    } catch (error) {
      console.error("❌ Error sending email:", error);
      alert("Failed to send email.");
    } finally {
      setEmailLoading(false);
    }
  };


  const formatDate = (isoDate) => {
    if (!isoDate) return ""; // Handle missing dates

    const date = new Date(isoDate);
    if (isNaN(date.getTime())) return "Invalid Date"; // Handle incorrect date formats

    const day = String(date.getUTCDate()).padStart(2, "0");
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const year = String(date.getUTCFullYear()).slice(-2);

    return `${day}/${month}/${year}`;
  };

  useEffect(() => {
    async function fetchClassesForScreener() {
      if (!selectedScreener || !schoolid || !selectedYear) return;

      try {
        setLoading(true);
        const allClasses = await getClassesByAssessmentId(selectedScreener, schoolid);

        console.log("🔍 Classes received from API:", allClasses); // Debug API response

        // Get the selected year's start & end dates
        const yearRange = YEAR_RANGES[selectedYear];
        if (!yearRange) {
          console.error("❌ Invalid year selected:", selectedYear);
          return;
        }

        console.log(`📅 Year Selected: ${selectedYear}`, yearRange);

        const startDate = new Date(yearRange.start);
        const endDate = new Date(yearRange.end);

        // Log each class to inspect `date_updated`
        allClasses.forEach(cls => {
          console.log(`🔹 Checking Class: ${cls.name}, Date Updated: ${cls.date_updated}`);
        });

        // Filter classes based on `date_updated`
        const filteredClasses = allClasses.filter(cls => {
          if (!cls.date_updated) {
            console.warn(`⚠️ Class ${cls.name} has no 'date_updated' value.`);
            return false;
          }

          const updatedDate = new Date(cls.date_updated);
          const isValidDate = !isNaN(updatedDate);
          const isInRange = isValidDate && updatedDate >= startDate && updatedDate < endDate;

          console.log(`✅ Class: ${cls.name} | Updated Date: ${cls.date_updated} | In Range: ${isInRange}`);

          return isInRange;
        });

        console.log("📌 Filtered Classes:", filteredClasses);
        setClasses(filteredClasses);
        setSelectedClasses([]);
        setSelectAll(false);
      } catch (error) {
        console.error("❌ Error fetching classes for selected screener:", error);
      } finally {
        setLoading(false);
      }
    }

    fetchClassesForScreener();
  }, [selectedScreener, schoolid, selectedYear]);


  console.log("filtered classes", classes);
  console.log("teacherEmail", teacherEmail);

  return (
    <Container fluid className="vh-100">
      <HeadingBanner name="Aladdin Export" isLoggedIn={true} cloud={true} teacherid={teacherid} />
      <Row className="h-100">
        <LeftPanel />

        <div className="school-input-container">
          <SchoolRollNumberInput
            onSchoolIdRetrieved={setSchoolid}
            schoolNameRetrieved={setSchoolName}
          />
        </div>

        <Col sm={{ span: 10, offset: 2 }} className="blue-background">
          <Card className="dynamic-box">
            {loading ? (
              <div className="loading-container d-flex flex-column align-items-center">
                <Spinner animation="border" role="status" className="mb-2" />
                <span>Loading...</span>
              </div>
            ) : !schoolid ? (
              <div className="step-message">
                <h4>Enter School Roll Number to begin</h4>
              </div>
            ) : !selectedYear ? (
              <>
                <h4>Select Academic Year</h4>
                <Row>
                  {["24/25", "23/24", "22/23"].map((year) => (
                    <Col md={4} key={year}>
                      <button
                        className={`light-blue-button year-button ${selectedYear === year ? "selected" : ""}`}
                        onClick={() => setSelectedYear(year)}
                      >
                        {year}
                      </button>
                    </Col>
                  ))}
                </Row>
              </>
            ) : !selectedScreener ? (
              <>
                <Button variant="secondary" className="back-button" onClick={() => setSelectedYear(null)}>
                  <FontAwesomeIcon icon={faArrowLeft} /> Back to Year Selection
                </Button>

                <h4>You are viewing screeners for {schoolName}</h4>
                <h5>Select an Assessment Point</h5>
                <Row>
                  {screeners.length > 0 ? (
                    screeners.map((screener) => (
                      <Col md={4} key={screener.id}>
                        <button
                          className={`light-blue-button assessment-button`}
                          onClick={() => setSelectedScreener(screener.id)}
                        >
                          {screener.name} <br />
                          <img
                            src={`/assets/imgs/${getIconFileName(screener.name)}-icon.png`}
                            className="season-assessment-icon"
                            alt={screener.name}
                          />
                        </button>
                      </Col>
                    ))
                  ) : (
                    <Col>
                      <Alert variant="info" className="text-center">
                        <FontAwesomeIcon icon={faExclamationCircle} size="3x" className="mb-3" />
                        <h4>No Screeners Available</h4>
                        <p>No screeners have been completed for this school yet.</p>
                      </Alert>
                    </Col>
                  )}
                </Row>
              </>
            ) : (
              <>
                {/* Back Button */}
                <Button variant="secondary" className="back-button" onClick={() => setSelectedScreener(null)}>
                  <FontAwesomeIcon icon={faArrowLeft} /> Back
                </Button>

                <h4>You are viewing classes for {schoolName}</h4>
                <h5>{screeners.find((s) => s.id === selectedScreener)?.name}</h5>
                <p>Select class/classes you would like to export Aladdin Results for.</p>

                <div className="class-selection-container">
                  {/* Class Table */}
                  <Card className="class-table-card">
                    {loading ? (
                      <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                      </Spinner>
                    ) : (
                      <Table bordered>
                        <thead>
                          <tr>
                            <th>Class Name</th>
                            <th>
                              Select All
                              <Form.Check
                                className="ml-2"
                                type="checkbox"
                                checked={selectAll}
                                onChange={handleSelectAll}
                              />
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {classes.map((cls) => (
                            <tr key={cls.classId}>
                              <td>{cls.name}</td>
                              <td>
                                <Form.Check
                                  type="checkbox"
                                  checked={selectedClasses.includes(cls.classId)}
                                  onChange={() => handleClassSelect(cls.classId)}
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    )}
                  </Card>

                  <div className="export-button-container">
                  <Button className="email-button" variant="success" onClick={() => setShowModal(true)} disabled={emailLoading}>
                    {emailLoading ? <Spinner animation="border" size="sm" /> : "EMAIL"}
                  </Button>

                  <Button className="export-button" variant="success" onClick={exportResults} disabled={exportLoading}>
                    {exportLoading ? <Spinner animation="border" size="sm" /> : "EXPORT"}
                  </Button>
                </div>
                </div>
              </>
            )}
          </Card>
        </Col>
      </Row>
      {window.innerWidth < window.innerHeight && <OrientationPrompt />}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Select Teacher to Email</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {teachers ? (
          <Form>
            {teachers.map((teacher) => (
              <Form.Check
                type="radio"
                name="selectedTeacher"
                key={teacher.id}
                label={`${teacher.name} (${teacher.email})`}
                value={teacher.email}
                onChange={(e) => {
                  setTeacherEmail(e.target.value);
                  setTeacherName(teacher.name);
                }}              />
            ))}
          </Form>
        ) : (
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShowModal(false)}>
          Close
        </Button>
        <Button variant="primary" onClick={emailResults}>
          SEND
        </Button>
      </Modal.Footer>
    </Modal>
    </Container>
  );
}

export default AladdinExport;