import React from "react";
import { Link } from "react-router-dom";

import { Col } from "react-bootstrap";

import './LeftPanel.css';

function LeftPanel() {
    return (

        <Col sm={2} className="left-panel mt-6">
            <nav className="side-nav">
                <ul className="nav-list">
                    <li><Link to="/masterdashboard">Home</Link></li>
                    <li><Link to="/reports">Reports</Link></li>
                    <li><Link to="/userexistence">Check User Existence</Link></li>
                    {/* <li><Link to="/retesttaskdashboard">Retest Task</Link></li> */}
                    {/* <li><Link to="/masterdashboard">Assign Assessments</Link></li> */}
                    <li><Link to="/resettasks">Resetting Tasks</Link></li>
                    <li><Link to="/schooladmin">School Admin</Link></li>
                    <li><Link to="/classadmin">Class Admin</Link></li>
                    <li><Link to="/aladdinexport">Aladdin Export</Link></li>
                </ul>
            </nav>
        </Col>
    )
}

export default LeftPanel;